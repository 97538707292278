import React, { useContext, useState, useEffect, useRef } from 'react';
import './RehberCard.css';
import AuthContext from '../../services/AuthContext';
import $ from 'jquery';
import { NavLink, Redirect } from "react-router-dom";
import baseURL from '../../services/baseURL';
import RefreshToken from '../../services/RefreshToken';
import SayfalamaButonlari from '../SayfalamaButonlari';
import ReactTooltip from 'react-tooltip';
import Select2 from 'react-select2-wrapper';
//import 'bootstrap';
import { Modal, TabContent } from 'react-bootstrap';
import api from '../../services/Api';
import { useQuery, QueryClientProvider } from "react-query";
import PageTitle from '../PageTitle';
//const queryClient = new QueryClient()

var getrehberdata_postparam;
var maxRecordsTotal = 40;

const downclass = "fa fa-angle-down";
const upclass = "fa fa-angle-up";
const btnstyle = { backgroundColor: 'transparent', border: 'none', padding: '0px', margin: '0px' };

function OrderComp(props) {
  if (props.col == props.sortingProperty) {
    return (
      <button className={props.sortedAscending ? downclass : upclass} style={btnstyle}></button>
    );
  }
  else {
    return (
      <div></div>
    );
  }
}

function RehberCard() {
  const context = useContext(AuthContext);
  return (
    <QueryClientProvider client={context.queryClient}>
      <RehberCard2 />
    </QueryClientProvider>
  )
}

function RehberCard2() {
  const [loading2, setLoading2] = useState(true);
  const [hata, setHata] = useState(false);
  const [filtre, setFiltre] = useState('SEÇİNİZ');
  const [elemanlar, setElemanlar] = useState(['SEÇİNİZ', 'ADI / ÜNVANI', 'REHBER NO', 'TC KİMLİK / VERGİ NO', 'ŞEHİR', 'İLÇE', 'MAHALLE / KÖY', 'TELEFON', 'TÜZEL KİŞİ']);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(40);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [sortingProperty, setSortingProperty] = useState('id');
  const [sortingColumnIndex, setSortingColumnIndex] = useState(0);
  const [sortedAscending, setSortedAscending] = useState(true);
  const [degerPasif, setDegerPasif] = useState(true);
  const [filtreTuru, setFiltreTuru] = useState("");
  const [filtreTuruSecildi, setFiltreTuruSecildi] = useState(false);
  const [baslik, setBaslik] = useState("Filtre Ekle");
  const [RedirectURL, setRedirectURL] = useState('');
  const [errorStatus, setErrorStatus] = useState('');
  const context = useContext(AuthContext);
  const [showEkle, setShowEkle] = useState(false);
  const inputRef = useRef(null);
  const [value3, setValue3] = useState(1);

const [style, setStyle] = useState({ });

  const handleFocus = (e, index) => {
    if (context.filterData[index]) {
      context.filterData[index].isFocused=true;
      var newInputArr = Array.from(context.filterData);
      context.setFilterData(newInputArr);
     }
  };

  const handleBlur = (e, index) => {
    if (context.filterData[index]) {
    context.filterData[index].isFocused=false;
    context.filterData[index].value = e.target.value;
    var newInputArr = Array.from(context.filterData);
    context.setFilterData(newInputArr);
    setCurrentPage(1);  }
  }
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') { 
      e.target.blur();
    }
  };

  String.prototype.turkishToUpper = function () {
    var string = this;
    var letters = { "i": "İ", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç", "ı": "I" };
    string = string.replace(/(([iışğüçö]))/g, function (letter) { return letters[letter]; })
    return string.toUpperCase();
  }

  String.prototype.turkishToLower = function () {
    var string = this;
    var letters = { "İ": "i", "I": "ı", "Ş": "ş", "Ğ": "ğ", "Ü": "ü", "Ö": "ö", "Ç": "ç" };
    string = string.replace(/(([İIŞĞÜÇÖ]))/g, function (letter) { return letters[letter]; })
    return string.toLowerCase();
  }
  const fltRef = useRef(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  var divStyle;

  const fetchRehberData = async () => {
    var flt = "";
    var i = -1;

    context.filterData.map((inputdata, index) => {
      i++;
      if (inputdata.value.trim() != '') {
        if (i > 0) flt = flt + "," + inputdata.key + " = " + inputdata.value; else flt = flt + inputdata.key + " = " + inputdata.value;
      }
    });

    flt = flt.turkishToUpper();
    //alert(flt.toUpperCase());
    getrehberdata_postparam = { params: { 'filtre': flt, 'currentPage': currentPage, 'recordsPerPage': recordsPerPage, 'sortingProperty': sortingProperty, 'sortingColumnIndex': sortingColumnIndex, 'sortedAscending': sortedAscending } };

    const { data } = await api.get('/getrehberdata', getrehberdata_postparam).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        //alert(error.response.data);
        //alert(error.response.status);
        //alert(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        //alert(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        //alert(' Hata: Internet bağlantınızı kontrol edin !', error.message);
        setHata(true);
      }
      //alert(error.config);
    });;
    //console.log(JSON.stringify(data));
    return data;
  };

  function handleInputChange(e, index) {
    context.filterData[index].value = e.target.value;
    var newInputArr = Array.from(context.filterData);
   // context.setFilterData(newInputArr);
    //setCurrentPage(1);
  }

  const addInput = (value) => {
    context.filterData.push({
      key: value.toString(),
      name: context.filterData.length.toString(),
      value: "",
      isFocused:false

    });
    var newInputArr = Array.from(context.filterData);
    context.setFilterData(newInputArr);
  };


  useEffect(() => {
    const updateStyles = () => {
      if (window.innerWidth < 990) {
        setStyle({
          contentStyle: {
            marginTop: "0px",
          },
          containerxxlStyle: {
            marginBottom: "20px",
          },
          py5Style: {
            paddingTop: "0px",
            paddingBottom: "0px",
          },
          divStyle : {
            width: '100%',
          }
        });
      } else {
        setStyle({
          contentStyle: {
            marginTop: "0px",
          },
          containerxxlStyle: {
            marginBottom: "20px",
          },
          py5Style: {
            paddingTop: "10px",
            paddingBottom: "0px",
          },
        });
      }
    };

    window.addEventListener('resize', updateStyles);

    updateStyles();

    return () => {
      window.removeEventListener('resize', updateStyles);
    };
  }, []);


  useEffect(() => {

    context.setCurrentPageName("Rehber");
    context.setCurrentSubPageName("");
  
  //   $("#sid").select2({
  //     dropdownParent: $('#myModal .modal-content')
  // });

    return () => {
      //unmount
    }
  }, [context.rehberFilter, currentPage, recordsPerPage, sortingProperty, sortingColumnIndex, sortedAscending]);

  const UnvanKisalt = (value) => {
    var a = value.length > 20 ?
      value.substr(0, 20) + '…' :
      value
    return a;
  }

  const ilkHarfiBuyukYap = (input) => {
    if (input == null) return "";
    return input.turkishToLower().replace(/(?:^|\s)\S/g, function (a) { return a.turkishToUpper(); });
  }
  const TelefonFormat = (value) => {
    var st = value;
    var data = value;
    if (data != null && data.length == 10) st = '(' + data.substr(0, 3) + ') ' + data.substr(3, 3) + ' ' + data.substr(6, 4);
    if (data != null && data.length == 7) st = '(242) ' + data.substr(0, 3) + ' ' + data.substr(3, 7);
    return st;
  }

  const ekleClick = () => {
    setShowEkle(!showEkle);

    //setRedirectURL('/rehber/ekle');
  };

  const detayClick = (id) => {
    setRedirectURL('/rehber/detay/' + id);
  };

  const duzenleClick = (e, id) => {
    e.preventDefault();
    setRedirectURL('/rehber/duzenle/' + id);
  };

  const DownloadXls = () => {
    $.ajax({
      type: 'GET',
      url: baseURL + "/exportxlsx",
      headers: {
        "Authorization": 'Bearer ' + localStorage.token
      },
      xhrFields: { responseType: 'blob' },
      success: function (data) {
        var blob = new Blob([data]);
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = "text.xlsx";
        link.click();
      },
      //
      error: function (jqXHR, textStatus, errorThrown) {
        if (jqXHR.status == 401) {
          RefreshToken(this);
        };
      }
      //
    });
  }

  const handleChange = (tags) => {

    //context.rehberFilter = tags;
    //fetchData(1, recordsPerPage, sortingProperty, sortingColumnIndex, sortedAscending);
  }

  const handleDetayButtonClick = (e) => {
    e.preventDefault();
    setRedirectURL('/rehber/detay/' + e.currentTarget.value);
  }

  const OncekiSayfa = () => {
    if (currentPage - 1 >= 1) {
      //context.setLoader(true);
      // setLoading2(true);
      setCurrentPage(parseInt(currentPage) - 1);
      //fetchData(currentPage, recordsPerPage, sortingProperty, sortingColumnIndex, sortedAscending);
    }
    else {

    }
  }

  const SonrakiSayfa = () => {

    if (currentPage + 1 <= totalPages) {
      //context.setLoader(true);
      //setLoading2(true);
      setCurrentPage(parseInt(currentPage) + 1);
      //fetchData(currentPage, recordsPerPage, sortingProperty, sortingColumnIndex, sortedAscending); 
    }
    else {

    }
  }

  const IlkSayfa = () => {
    if (currentPage != 1) {
      //context.setLoader(true);
      //setLoading2(true);
      setCurrentPage(1);
      //fetchData(currentPage, recordsPerPage, sortingProperty, sortingColumnIndex, sortedAscending);
    }
  }

  const SonSayfa = () => {
    if (currentPage != totalPages) {
      //setLoader(true);
      // setLoading2(true);
      setCurrentPage(totalPages);

      //fetchData(currentPage, recordsPerPage, sortingProperty, sortingColumnIndex, sortedAscending);
    }
  }

  //var recordsTotal = data == null ? 0 : data.recordsTotal;
  var flt = "";
  var i = -1;
  context.filterData.map((inputdata, index) => {
    i++;
    if (inputdata.value.trim() != '') {
      if (i > 0) flt = flt + "," + inputdata.key + " = " + inputdata.value; else flt = flt + inputdata.key + " = " + inputdata.value;
    }
  });

  flt = flt.toUpperCase();

  getrehberdata_postparam = { params: { 'filtre': flt, 'currentPage': currentPage, 'recordsPerPage': recordsPerPage, 'sortingProperty': sortingProperty, 'sortingColumnIndex': sortingColumnIndex, 'sortedAscending': sortedAscending } };

  var dynamicParam = '/getrehberdata' + JSON.stringify(getrehberdata_postparam);
  //alert(dynamicParam);


  const FilterButton = ({ name }) => {
    const handleClick = () => {
      context.filterData.splice(Number(name), 1);
      var newInputArr2 = Array.from(context.filterData);
      context.setFilterData(newInputArr2);
    };

    return (
      <button onClick={handleClick} style={{ marginLeft: '5px', width: '25px', height: '25px', float: 'right', color: '#0098fe', backgroundColor: '#f5f8fa', border: 'none', fontWeight: 'bold' }}>X</button>
    );
  };


  const onhandleChange = (e, index) => {
    const regex = /^[0-9]*$/;
    if (!regex.test(e.target.value)) {
      return;
    }

    context.filterData[index].value = e.target.value;
    var newInputArr = Array.from(context.filterData);
    context.setFilterData(newInputArr);
    setCurrentPage(1);
  };

  const { isLoading, isFetching,
    isError,
    refetch,
    data } = useQuery(['queryClient', dynamicParam], fetchRehberData, {
      onSuccess: (data) => {
        //  alert('/getrehberdata'+JSON.stringify(context.getrehberdata_postparam));
        setRecordsTotal(data.recordsTotal);
        setTotalPages(Math.ceil(data.recordsTotal / recordsPerPage));
        maxRecordsTotal = data.recordsTotal;
        context.setCurrentRecCount(data.recordsTotal);
        //alert((Math.ceil(data.recordsTotal / recordsPerPage)));
      },
      onError: (error) => {
        //alert(error.message);
        setHata(true);
        if (hata)  {
          return (
      
            <div id="loadericondiv2" style={{ display: 'block', color: 'lightgray', fontSize: '15px', marginTop: '0px' }} className="float-left">
              <div>Bir hata oluştu. {error.response.status}</div>
            </div>
          );
        }

        if (error) if (error.response.status == "402") {
          return (
      
            <div id="loadericondiv2" style={{ display: 'block', color: 'lightgray', fontSize: '15px', marginTop: '0px' }} className="float-left">
              <div>Bu bölümü görüntüleme yetkiniz yok. {error.response.status}</div>
            </div>
      
          )
        };
        //console.log(error.message)
      },
    });

  if (RedirectURL) return <Redirect push to={RedirectURL} />;



  if ($("#kt_body").attr('data-kt-aside-minimize') === 'on') {
    divStyle = {
      width: 'calc(100% + 50px)'
      // Diğer stil özellikleri buraya eklenebilir
    }
  }
  else {
    divStyle = {
      width: 'calc(100% + 250px)',
      // Diğer stil özellikleri buraya eklenebilir
    }
  }


  return (

    (errorStatus != "402") ?
    <div className="RehberCard">

        <div className="content d-flex flex-column flex-column-fluid"  style={style.contentStyle} id="kt_content">

          <div className="post d-flex flex-column-fluid" id="kt_post">

            <div id="kt_content_container" className="container-xxl" style={style.containerxxlStyle}>
              <div className="pagetitle">
                <PageTitle title={" Rehber"} count={recordsTotal}/>
              </div>

              <div className="row gy-5 g-xl-12">

                <div className="col-xl-12">

                  <div className="card sticky" style={{ marginBottom: '6px', marginTop: '0px', paddingTop: '5px', paddingBottom: '8px' }}>
                    <div className="col" style={{ textAlign: 'left', paddingLeft: '10px' }}>
                      <div>
                        <div style={{ float: 'left', marginTop: '5px' }}>
                         
                        <NavLink to={"rehber/ekle"} data-tip="Ekle" className="btn btn-icon btn-bg-light btn-color-primary btn-sm me-1">
                                              <span className="svg-icon svg-icon-3">
                                                <i className="fas fa-user-plus"></i>
                                              </span>
                        </NavLink>
                         
                     

                          <a onClick={DownloadXls} data-tip="Excel'e Aktar" className="btn btn-icon btn-bg-light  btn-sm me-1">
                            <i className="fas fa-file-excel"></i>
                          </a>
                        </div>

                        <div className="dropdown" style={{ float: 'left', marginTop: '5px' }}>
                          <a className="btn btn-icon btn-bg-light  btn-sm me-1" onClick={e => ReactTooltip.hide()} data-tip="Filtre Ekle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-filter"></i>
                          </a>
                          <div className="dropdown-menu custom-scrollbar" aria-labelledby="dropdownMenuButton" style={{ border: "3px solid #dee2e6", maxHeight: "300px", overflowY: "auto" }}>
                            <a className="dropdown-item" onClick={() => addInput("Adı/Ünvanı")} href="#">Adı/Ünvanı</a>
                            <a className="dropdown-item" onClick={() => addInput("Müşteri No")} href="#">Müşteri No</a>
                            <a className="dropdown-item" onClick={() => addInput("Tc/Vergi No")} href="#">Tc/Vergi No</a>
                            <a className="dropdown-item" onClick={() => addInput("Şehir")} href="#">Şehir</a>
                            <a className="dropdown-item" onClick={() => addInput("İlçe")} href="#">İlçe</a>
                            <a className="dropdown-item" onClick={() => addInput("Mahalle/Köy")} href="#">Mahalle/Köy</a>
                            <a className="dropdown-item" onClick={() => addInput("Telefon")} href="#">Telefon</a>
                            <a className="dropdown-item" onClick={() => addInput("Statü")} href="#">Statü</a>
                          </div>
                        </div>
                        <div style={{ float: window.innerWidth > 990 ? 'right' : 'left' }}>
                          <SayfalamaButonlari IlkSayfa={IlkSayfa} OncekiSayfa={OncekiSayfa} SonrakiSayfa={SonrakiSayfa} SonSayfa={SonSayfa} currentPage={currentPage} recordsTotal={data == null ? maxRecordsTotal : data.recordsTotal} recordsPerPage={recordsPerPage} />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/**/}
                  {(() => {
                    if (context.filterData.length > 0) {
                      return (

                        <div className="card sticky2" style={{ marginBottom: '8px', marginTop: '0px', paddingTop: '5px', paddingBottom: '5px', zIndex: '0' }}>
                          <div className="col" style={{ textAlign: 'left', paddingLeft: '10px', zIndex: '0' }}>

                            {context.filterData.map((inputdata, index) => (
                              <div key={index} style={{ float: "left", paddingBottom: '0px', marginBottom: '3px', marginTop: '0px', zIndex: '0' }}>
                                <div className="fv-row fv-plugins-icon-container" style={{ backgroundColor: '#f5f8fa', padding: '2px', border: 'solid 1px lightgray', width: '300px', paddingBottom: '0px', marginBottom: '0px', marginRight: '20px', borderRadius: '0.475rem', zIndex: '0' }}>
                                  <label className="fw-bold mb-2" style={{ marginTop: '0px', marginLeft: '2px', marginRight: '5px', color: '#0098fe' }}> {inputdata.key.replace(/ /g, '\u00a0')}</label>
                                  <FilterButton name={index.toString()}>
                                    X
                                  </FilterButton>
                                  <div style={{ float: 'right', border: 'solid 1px lightgray' }}>

                                    {inputdata.key === "Adı/Ünvanı" && (
                                      <input
                                        defaultValue={inputdata.value}
                                        key={inputdata.key}
                                        onChange={(e) => handleInputChange(e, index)}
                                        onFocus={(e) => handleFocus(e, index)}
                                        onBlur={(e) => handleBlur(e, index)}
                                        onKeyPress={handleKeyPress}
                                        name={inputdata.name}
                                        type="text"
                                        spellCheck={false}
                                        autoComplete="off"
                                        style={{ width: '150px', border: 'none', backgroundColor: context.filterData[index].isFocused? 'floralwhite':'white'  }}
                                      />
                                    )}

                                    {inputdata.key === "Müşteri No" && (
                                      <input
                                        value={inputdata.value}
                                        key={inputdata.key}
                                        onChange={(e) => onhandleChange(e, index)}
                                        onFocus={(e) => handleFocus(e, index)}
                                        onBlur={(e) => handleBlur(e, index)}
                                        onKeyPress={handleKeyPress}
                                        name={inputdata.name}
                                        type="text"
                                        spellCheck={false}
                                        autoComplete="off"
                                        style={{ width: '150px', border: 'none',backgroundColor: context.filterData[index].isFocused ? 'floralwhite':'white' }}
                                      />
                                    )}

                                    {inputdata.key === "Statü" && (                                      
                                        <Select2 
                                        className="form-control form-control-solid"
                                        // style={{ width: "100%", height:"500px" }}
                                        multiple={false}
                                        data={[
                                          { text: 'Gerçek Kişi', id: 0 },
                                          { text: 'Tüzel Kişi', id: 1 },
                                        ]}
                                        defaultValue={1}
                                        value={inputdata.value}
                                        key={inputdata.key} 
                                        name={inputdata.name}
                                        options={{
                                         placeholder: 'Seçiniz',
                                        minimumResultsForSearch: -1,
                                        }}
                                      style={{backgroundColor:"white"}}
                                      onChange={(e) => onhandleChange(e, index)}
                                      />

                                    )}

                                  </div>
                                </div>
                              </div>
                            ))}
                            {/* {RehberFiltre()} <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Click to add a user"></div> */}
                          </div>
                        </div>

                      )
                    }
                  })()}

                  {(() => {
                    if (isLoading) {

                      return (
                        <div className="loader-container" style={divStyle}>
                          <div className="nloader"></div>
                        </div>
                      )

                    } else {

                      return (
                        <div>
                          <div className="card card-xl-stretch mb-5 mb-xl-12">
                            <div className="card-body py-3">
                              <div className="table-responsive">

                                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                  <thead>
                                    <tr className="fw-bolder text-muted">
                                      <th className="min-w-10px">Müşteri No</th>
                                      <th className="min-w-150px">Adı Soyadı/Ünvanı</th>
                                      <th className="min-w-140px">TC Kimlik/Vergi No</th>
                                      <th className="min-w-140px">Şehir</th>
                                      <th className="min-w-140px">İlçe</th>
                                      <th className="min-w-140px text-end">İşlem&nbsp;&nbsp;&nbsp;&nbsp;</th>

                                    </tr>
                                  </thead>
                                  {data != null ? data.data.map((val, key) => {
                                    return (
                                      <tr key={key}>
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <div className="d-flex justify-content-start flex-column">
                                              <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">{val.MNO}</a>
                                              <span className="text-muted fw-bold text-muted d-block fs-7"></span>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <div className="d-flex justify-content-start flex-column">
                                              <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">{ilkHarfiBuyukYap(UnvanKisalt(val.UNVANIADI))}</a>
                                              <span className="text-muted fw-bold text-muted d-block fs-7">{val.tipi} {val.musterino == null ? "" : " / ECS-" + val.musterino.toString()}</span>
                                            </div>
                                          </div>
                                        </td>

                                        <td>
                                          <div className="d-flex align-items-center">
                                            <div className="d-flex justify-content-start flex-column">
                                              <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">{val.ID}</a>
                                              <span className="text-muted fw-bold text-muted d-block fs-7"></span>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <div className="d-flex justify-content-start flex-column">
                                              <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">{ilkHarfiBuyukYap(val.SEHIR)}</a>
                                              <span className="text-muted fw-bold text-muted d-block fs-7"></span>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <div className="d-flex justify-content-start flex-column">
                                              <a href="#" className="text-dark fw-bolder text-hover-primary fs-6">{ilkHarfiBuyukYap(val.ILCE)}</a>
                                              <span className="text-muted fw-bold text-muted d-block fs-7"></span>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="d-flex justify-content-end flex-shrink-0">


                                            <NavLink to={"rehber/duzenle/" + val.ID} data-tip="Düzenle" className="btn btn-icon btn-bg-light btn-color-primary btn-sm me-1">
                                              <span className="svg-icon svg-icon-3">
                                                <i className="fas fa-pen"></i>
                                              </span>
                                            </NavLink>

                                            <a href="#" data-tip="Sil" className="btn btn-icon btn-bg-light btn-color-danger btn-sm">
                                              <span className="svg-icon svg-icon-3">
                                                <i className="fas fa-trash"></i>
                                              </span>
                                            </a>

                                          </div>
                                        </td>
                                      </tr>

                                    )

                                  }) : null}                                         <ReactTooltip place="bottom" type="light" className='customtooltip'
                                    textColor='white' backgroundColor='#009df6' effect='solid' />

                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  })()}

                </div>
              </div>

            </div>
          </div>

        </div>


        {/* <div className="cardfooter">
     <div className="float-right">{numberWithCommas(recordsTotal)} kayıttan  {(currentPage - 1) * recordsPerPage == 0 ? 1 : numberWithCommas((currentPage - 1) * recordsPerPage + 1)} ile {(currentPage - 1) * recordsPerPage + recordsPerPage > recordsTotal ? numberWithCommas(recordsTotal) : numberWithCommas((currentPage - 1) * recordsPerPage + recordsPerPage)} arası kayıtlar gösteriliyor.</div>
   </div> */}

        <Modal show={showEkle} onHide={() => setShowEkle(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Rehber / Ekle</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px" style={{ maxHeight: "392px" }}>

              <div className="fv-row mb-7 fv-plugins-icon-container">

                <label className="required fs-6 fw-bold mb-2">İşletme</label>

                <Select2 id="sid"
                className="form-control form-control-solid"
                style={{ width: "100%", height:"500px" }}
                multiple={false}
                data={[
                  { text: 'Evet', id: 1 },
                  { text: 'Hayır', id: 2 },
                ]}
                defaultValue={1}
                value={value3}
                options={{
                  placeholder: 'search by tags',
                  minimumResultsForSearch: -1,
                }}
              />
                
                </div>
                         
              <div className="fv-row mb-7 fv-plugins-icon-container">

                <label className="fs-6 fw-bold mb-2">
                  <span className="required">Email</span>
                  <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Email address must be active" aria-label="Email address must be active"></i>
                </label>

                <input type="email" className="form-control form-control-solid" placeholder="" name="email" value="sean@dellito.com" />

              </div>




              <div className="row g-9 mb-7">

                <div className="col-md-6 fv-row fv-plugins-icon-container">

                  <label className="required fs-6 fw-bold mb-2">State / Province</label>

                  <input className="form-control form-control-solid" placeholder="" name="state" value="Victoria" />

                  <div className="fv-plugins-message-container invalid-feedback"></div></div>

                <div className="col-md-6 fv-row fv-plugins-icon-container">

                  <label className="required fs-6 fw-bold mb-2">Post Code</label>

                  <input className="form-control form-control-solid" placeholder="" name="postcode" value="3000" />

                  <div className="fv-plugins-message-container invalid-feedback"></div></div>

              </div>

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">

                <label className="required fs-6 fw-bold mb-2">Town</label>

                <input className="form-control form-control-solid" placeholder="" name="city" value="Melbourne" />

                <div className="fv-plugins-message-container invalid-feedback"></div></div>

              <div className="fv-row mb-7">

              </div>

            </div>
            <Modal.Footer>

              <button type="reset" id="kt_modal_add_customer_cancel" className="btn btn-light me-3">Discard</button>

              <button type="submit" id="kt_modal_add_customer_submit" className="btn btn-primary">
                <span className="indicator-label">Submit</span>
                <span className="indicator-progress">Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
              </button>

            </Modal.Footer>

          </Modal.Body>
        </Modal>


      </div> : <div className="card card-outline cardm" style={{ width: "98%", height: "560px" }}>
        <div className="child">
          {errorStatus != "402" ?
            <div id="loadericondiv2" style={{ display: 'block', color: 'lightgray', fontSize: '50px', marginTop: '0px' }} className="float-left">
              <i id="loadericon" className="fas fa-sync fa-spin"></i>
            </div> : <div style={{ display: 'block', color: 'lightgray', fontSize: '20px', marginTop: '0px', marginLeft: '-150px' }} className="float-left">
              <i id="loadericon">Bu bölümü görüntüleme yetkiniz yok !</i>
            </div>}

        </div>
      </div>
  );

}

export default RehberCard;